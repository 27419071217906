@font-face {
    font-family: 'MyWebFont';
    src: url('../../common/fonts/CenturyGothic.ttf'); /* IE9 Compat Modes */
    src: url('../../common/fonts/CenturyGothic.ttf?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../../common/fonts/CenturyGothic.ttf') format('ttf'), /* Super Modern Browsers */ url('../../common/fonts/CenturyGothic.ttf') format('ttf'), /* Pretty Modern Browsers */ url('../../common/fonts/CenturyGothic.ttf') format('truetype'), /* Safari, Android, iOS */ url('../../common/fonts/CenturyGothic.ttf#svgFontName') format(''); /* Legacy iOS */
}

body, input, select, textarea {
    font-family: 'MyWebFont', Fallback, sans-serif;
    color: rgba(50, 50, 53, 0.9);
    /*font-size: 14px;*/
}

.header {
    background: rgba(16, 38, 66, 1);
}

.logo {
    height: 50px;
    content: "";
    padding: 10px;
    margin-left: 10px;
    color: white;
}

.logo:before {
    height: 40px;
    content: "Care Orthopedie";
    padding: 10px;
    margin-left: 10px;
    color: white;
    font-size: 30px;
    margin-top:5px;
    position: absolute;
}

.header-icon {
    color: rgba(16, 38, 66, 1);
}


.path-background {
    background: rgba(16, 38, 66, 0.05);
}

.path {
    background: rgba(16, 38, 66, 0.1);
    color: rgba(16, 38, 66, 1);
}

.path a{
    color: rgba(16, 38, 66, 1);
}

.sub-bar {
    background: rgba(225,226,241, 0.7);
    color: rgba(16, 38, 66, 1);
}


.backend-item-header {
    background: rgba(16, 38, 66, 0.7);
    color: rgba(255, 255, 255, 0.9);
}


th {
    /*background: rgba(225, 226, 241, 1);*/
    background: rgba(53,53,53, 0.55);
    color: rgba(255, 255, 255, 0.95);
    /*color: rgba(39,52,139, 1);*/
    border: none;
}
