
.login-box-container {
    background: rgba(255, 255, 255, 1);
    top: 150px;
    width: 90%;
    position: absolute;
    left: 50%;
    margin-left: -45%;
    border: rgba(200, 200, 200, 1) solid 1px;
    border-radius: 5px;
    margin-bottom: 100px;
}

.login-box {
    padding: 30px;
}

.forgot-password-container {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1em;
    text-decoration: underline;
    color: rgba(50, 131, 189, 0.9);
    text-align: center;
    width: 100%;
    float: left;
}

#change-password {
    display: none;
}

#confirm-code {
    display: none;
}

input,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
select,
textarea {
    width: 93%;
}


.user-box-container {
    background: rgba(255, 255, 255, 1);
    top: 150px;
    width: 90%;
    position: absolute;
    left: 50%;
    margin-left: -45%;
    border: rgba(200, 200, 200, 1) solid 1px;
    border-radius: 5px;
    margin-bottom: 100px;
}

.user-box {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 3%;
}

.button-container {
     padding-top: 20px;
    padding-right: 90px;
    margin-bottom: 50px;
}


/*@media screen and (max-width: 3000px) {*/


/*}*/


@media screen and (min-width: 760px) {


    .login-box-container {
        background: rgba(255, 255, 255, 1);
        top: 150px;
        width: 80%;
        position: absolute;
        left: 50%;
        margin-left: -40%;
        border: rgba(200, 200, 200, 1) solid 1px;
        border-radius: 5px;
    }


    .user-box-container {
        background: rgba(255, 255, 255, 1);
        top: 150px;
        width: 80%;
        position: absolute;
        left: 50%;
        margin-left: -40%;
        border: rgba(200, 200, 200, 1) solid 1px;
        border-radius: 5px;
    }


    .user-box {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

}


@media screen and (min-width: 1020px) {

    .login-box-container {
        background: rgba(255, 255, 255, 1);
        top: 150px;
        width: 66%;
        position: absolute;
        left: 50%;
        margin-left: -33%;
        border: rgba(200, 200, 200, 1) solid 1px;
        border-radius: 5px;
    }

    .user-box-container {
        background: rgba(255, 255, 255, 1);
        top: 150px;
        width: 66%;
        position: absolute;
        left: 50%;
        margin-left: -33%;
        border: rgba(200, 200, 200, 1) solid 1px;
        border-radius: 5px;
    }


    .user-box {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

}

@media screen and (min-width: 1200px) {

    .login-box-container {
        background: rgba(255, 255, 255, 1);
        top: 150px;
        width: 50%;
        position: absolute;
        left: 50%;
        margin-left: -25%;
        border: rgba(200, 200, 200, 1) solid 1px;
        border-radius: 5px;
    }

    .user-box-container {
        background: rgba(255, 255, 255, 1);
        top: 150px;
        width: 50%;
        position: absolute;
        left: 50%;
        margin-left: -25%;
        border: rgba(200, 200, 200, 1) solid 1px;
        border-radius: 5px;
    }

    .user-box {
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
